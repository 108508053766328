<template>
    <span>Laravel Boilerplate</span>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
